<template>
  <div class="merchants">
    <v-card>
      <v-card-title>
        Merchants
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="getMerchants"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
        >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            @click="printcode(item)"
            >Show QR Code</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="qrdialog"
      max-width="380"
    >
      <v-card>
        <v-card-title class="headline">
          Print
        </v-card-title>
        <v-card-text>
          Scan this code with the wallet to
          send
          to <code>{{topupRecepient}}</code>
        </v-card-text>
        <v-card-text>
          <img :src="qrCode" alt="QRCode" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="qrdialog = false"
            >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script charset="utf-8">
import {mapState, mapActions, mapGetters} from 'vuex'
import QRious from 'qrious'

export default {
  name: 'Merchants',
  data: () => ({
    qrdialog: false,
    search: '',
    currItem: null,
    topupRecepient: null,
    headers: [
      { text: 'id', value: 'id' },
      { text: 'Name', value: 'name'},
      { text: 'Address', value: 'address' },
      { text: 'Balance', value: 'balance' },
      { text: 'TX Activity', value: 'tx' },
      { text: 'Actions', value: 'actions', sortable: false },
    ]
  }),
  methods: {
    printcode(item) {
      this.topupRecepient = item.address
      this.currItem = item
      this.qrdialog = true
      const q = new QRious({
        size: 330,
        value: item.address
      })
      const u = q.toDataURL()
      this.printImage(u)
    },
    printImage(source) {
      var Pagelink = "about:blank";
      var pwa = window.open(Pagelink, "_new");
      pwa.document.open();
      pwa.document.write(this.imagetoPrint(source));
      pwa.document.close();
    },
    imagetoPrint(source)
    {
      return "<html><head> <style type='text/css' media='print'> @page { size: auto; margin: 0;  } </style> <title></title><scri"+"pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" + "pt></head><body onload='step1()'>\n" +
        "<img src='" + source + "' /></body></html>";
    },
    ...mapActions(['loadMerchants'])
  },
  mounted() {
    this.loadMerchants()
  },
  computed: {
    qrCode() {
      const link = `${this.topupRecepient}`
      const q = new QRious({
        size: 330,
        value: link
      })
      return q.toDataURL()
    },
    ...mapGetters([
      'getMerchants'
    ]),
    ...mapState([
      'loading'
    ])
  }
}
</script>
